@import "../../styles/variables.scss";
@import '../../styles/App.scss';
.container {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-evenly;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 3px;
    border-radius: 10px;
    align-items: center;
    height: 90%;
    box-shadow: $shadow-6;
    max-height: 70px;

    min-height: 50px;
}


.tab_box {
    width: 100%;
    height: 100%;
    border-radius: 15px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px;
    
    &:hover {
        box-shadow: $shadow-3;
    }
    .tab_title {
        font-weight: 600;
        font-size: 13px;
        color: gray;
        text-align: center
    }
}

.tab_box_active {
    @extend .tab_box;
    background-image: linear-gradient(to right, #00b4db, #333cd8);
    box-shadow: $shadow-3;
    .tab_title {
        color: white;
        font-weight: 700;
        
    }
}