@import "../../styles/variables.scss";
@import "../../styles/App.scss";


.content_container {
    background-color: rgba(255, 255, 255, 0.3);
    width: 100%;
    height: calc(100% - 20px);
    padding-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(50px);
    border-radius: 20px;
    margin-top: 20px;
}

.sections_container {
    width: 96%;
    margin-left: 2%;
    border-radius: 20px;
    border: 1px solid $primary-color;
    box-shadow: $shadow-3;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.new_section_box {
    @extend .center-column;
    padding: 6px;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: $shadow-2;
    border-radius: 10px;
    position: absolute;
    right: 3%;
    background-color: #f7f8f9;
}

.save_button {
    position: absolute !important;
    bottom: 8%;
    left: calc(50% - 50px);
    color: white;
    padding: 12px 0px;
    border-radius: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
    font-size: 16px;
    font-weight: 600;
    background-color: $primary-color;
    z-index: 10;
}
    