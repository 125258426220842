@import '../../styles/variables.scss';
@import '../../styles/App.scss';

.chat_container {
    width: 100%;
    height: 100%;
    box-shadow: $shadow-6;
    display: flex;
    @extend .center-column;
    border-radius: 20px;
    overflow: hidden;
}

.chat_title_container {
    @extend .center-row;
    justify-content: space-between;
    
    height: 10%;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    backdrop-filter: blur(30px);
    background-color: rgba(255, 255, 255, 0.5);
}
.chat_title {
    font-size: 18px;
    font-weight: 600;
}

.chat_content_container {
    @extend .center-column;
    justify-content: flex-start;
    height: 75%;
    width: 90%;
    padding-top: 10px;
    padding-bottom: 10px;
    overflow-y: scroll;
}

.message_box_container_right{
    @extend .center-column;
    align-items: flex-start;
    
    align-self: flex-end;
    width: 80%;
    padding: 10px;
    background-color: $primary-color;
    border-radius: 22px;
    border-bottom-right-radius: 0px;
    box-shadow: $shadow-3;
    color: white;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 20px;
    .chat_message_time {
        color: #e9e9e9; 
        font-weight: 200;
        font-size: 12px;
    }
}

.message_box_container{
    @extend .center-column;
    align-items: flex-start;
    align-self: flex-start;
    width: 80%;
    padding: 10px;
    border: 1px solid $primary-color;
    box-shadow: $shadow-3;
    border-radius: 22px;
    border-bottom-left-radius: 0px;

    margin-top: 10px;
    margin-bottom: 20px;
    color: black;
    font-size: 14px;
    .chat_message_user {
        color: #5342f0; 
        font-weight: 500
    }
    .chat_message_time {
        color: #595858; 
        font-weight: 200;
        font-size: 12px;
    }

}

.message_box_container_system{
    @extend .center-column;
    color: #595858;
    margin-top: 10px;
    margin-bottom: 20px;
    .chat_message_time {
        color: #595858; 
        font-weight: 200;
        font-size: 12px;
    }
}

.chat_input_container {
    @extend .center-row;
    justify-content: space-evenly;
    height: 15%;
    width: 100%;


}

.chat_input {
    width: 80%;
}

.chat_input_props {
    padding: 100px
}