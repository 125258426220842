
@import "../../styles/variables.scss";
@import '../../styles/App.scss';

.nav_link {
    color: black;
    padding: 12px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    margin: 5px;
    text-decoration: none;
    font-size: 14px;
    height: 45px;
    
}

#sidebar button {
    color: black;
    padding: 12px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    margin-left: 5px;
    text-decoration: none;
}

.nav_link_active {

    color: black;
    padding: 12px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 10px;
    font-size: 14px;
    box-shadow: $shadow-2;
    text-decoration: none;
    font-weight: 600;
    margin: 5px;
}

.nav_link:hover{
    text-decoration: none;
    color: black;
    background-color: #9798993d;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}




