@import "../../styles/variables.scss";
@import '../../styles/App.scss';


.inventory_room_container {
    @extend .center-column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 7px 10px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
    border-radius: 20px;
    width: 90%;
    margin-bottom: 10px;
    background-color: white;
    padding-bottom: 20px;
    margin-top: 10px

}



.inventory_title {
    @extend .center-column;
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    background-color: rgba(235, 235, 235, 0.9);
    justify-content: space-around;
    border-radius: 20px 20px 20px 20px;
    z-index: 2;
    /* margin-bottom: 10px; */
    cursor: pointer;

}
.MuiOutlinedInput-root {
    padding: 0px !important;
}

.MuiInputBase-input {
    padding: 5px !important;
}