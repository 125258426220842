@import "./variables.scss";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.center-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.center-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.shadow-1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.shadow-2 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.shadow-3 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.shadow-4 {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.shadow-5 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.shadow-6 {
  box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
}

.shadow-7 {
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.content-container-wrap {
  width: 100vw;
  // margin-left: 1vw;
  height: 92vh;
  // margin-top: 1vh;
  // border-radius: 20px;
  // background-color: rgba(255, 255, 255, 0.50);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  
}

.content-container{
  // border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.25);
  width: 100%;
  height: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  backdrop-filter: blur(15px);
}

.ql-editor {
  min-height: 150px;
}

.scroll-container {
  // @extend .center-column;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 92%;
  align-items: start;
  width:100%;
  justify-content: space-evenly;
  overflow: scroll;

}


.table_container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 85%;
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
  justify-content: flex-start;

  align-content: start;
  margin-bottom: 20px;
  overflow: scroll;
  
}


table {
  width: 98%;
  height: 90%;
  border-collapse: collapse;
  overflow: hidden;
  // box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: $shadow-2;
  border-radius: 5px;
}
th,
td {
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #222121;
  
}

thead tr {
  // border: 1px solid $primary-color;
}
thead td:first-child {
  border-top-left-radius: 15px; 
  border-bottom-left-radius: 15px;
}
thead td:last-child {
  border-bottom-right-radius: 15px; 
  border-top-right-radius: 15px; 
  
}

// thead th:first-child{
//   border-top-left-radius: 20px;
 
// }

thead th {
  // background-color: $primary-color;
  // background-color: #0a001caa;
  // background-color: #020b5e;
  // color: #f8f8fa;
  background-color: #f7fafb64;

  color: #222121;
  // box-shadow: $shadow-2;
  font-weight: 700;
}

thead tr th:hover {
  box-shadow: $shadow-3;
  background-color: rgba(255, 255, 255, 0.8);
  // border-radius: 8px;
}


th {
  text-align: left;
}

tbody tr {
  font-weight: 500;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
}

tbody tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.8);
}
tbody tr:hover {
  background-color: #acafb3;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
}
tbody td {
  position: relative;
}

.table-button {
  margin-right: 20px;
  background-color: #3b82f6;
  border-radius: 10px;
  color: #f4f4f4;
  padding: 5px 10px;
  width: 100px;
  text-align: center;
  box-shadow: $shadow-2;
}

.MuiOutlinedInput-root {
  padding: 0px !important;
}

.MuiInputBase-input {
  padding: 5px !important;
}