@import '../../styles/App.scss';
@import "../../styles/variables.scss";
.ticket {
    @extend .center-column;
    width: 250px;
    height: 100px;
    border-radius: 20px;
    background-color: #f5f5f5;
    box-shadow: $shadow-2;
    margin-left: 3%;
    margin-bottom: 30px;
    padding: 20px;
    align-items: "self-start";

    &:hover {
        box-shadow: $shadow-6;
        cursor: pointer;
        background-color: #fff
    };
    margin-top: 20px;
}

.submit_button {
    position: absolute;
    bottom: 2%;
    left: 50%;
    color: #fff;
    width: 100px;
    padding: 12px 0px;
    border-radius: 20px;
    background-color: $primary-color;
    box-shadow: $shadow-2;
    font-size: 16px;
    font-weight: 600;
}
