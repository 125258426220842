.amplify-button:hover {
  background: darkgray;
}
.amplify-button[data-variation='primary'] {
  background: #128e33;
}
/* .amplify-tabs {
  display: none;
} */
.login{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  min-width: 100vw;
  height: 100%;
}

[data-amplify-authenticator] [data-amplify-router] {
  border-radius: 20px;
  margin-top: 10px;
  border-style: none;
}

.amplify-tabs-item[data-state=active] {
  border-radius: 10px;
}