.section-container {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 10px;
    border-radius: 20px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 20px;
    width: 90%;
    
}

.rate-linear-gradient-bg {
    background-image: linear-gradient(to right, #9292e7 0%, #4e83e6 100%);
}

.rate-linear-gradient-bg1 {
    background-image: linear-gradient(to right, #45d151 0%, #039e35 100%);
}

.rate-entry-title {
    font-size: 14px;
    color: #dcf2df;

}

.rate-entry-title-last {
    font-size: 20px;
    color: #bff2c6;
    font-weight: 300;
}

.rate-input-last {
    color: #bff2c6;
    font-weight: 800;
    font-size: 18px;
    width: 20%;
}

.rate-input {
    color: white;
    font-weight: 600;
    font-size: 14px;
    width: 20%;
}

.inventory-list-container {
    box-shadow:rgba(0, 0, 0, 0.5) 0px 1px 3px;
    border-radius: 20px;
}

.icon_button {
    background-color: #e2e9f6 !important;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    min-width: 35px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px;
}