.modal-wrapper {
    position: fixed;
    left: 0;
    top: 200px;
    width: calc(100% - 200px);
    height: calc(100vh - 100px);
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    padding: 50px 20px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.modal {
    width: 100%;
    height: fit-content;
    max-width: 300px;
    background-color: #a4d1c8;
    position: relative;
    border: 5px;
    box-shadow: 2px 4px 20px 0px rgba(0, 0, 0, 0.75);
    border-radius: 10px;
    padding: 20px;
}

.modal p {
    font-size: 12px;
    line-height: 24px;
}

.detail-tab {
    padding: 5px 10px;
    cursor: pointer;

}

.detail-tab-active {
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #c0bfbf;
    border-radius: 10px;
    background-color: #3b82f6;
    color: white
}


