@import "../../styles/variables.scss";
@import '../../styles/App.scss';

.content_contaier {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

.preview_info_container {
    width: 96%;
    margin-left: 2%;
    border-radius: 20px;
    border: 1px solid $primary-color;
    box-shadow: $shadow-3;
    padding: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    flex-wrap: wrap;
    margin-bottom: 40px;
}



.section_container {
    @extend .center-column;
    justify-content: flex-start;
    margin: 5px;
    margin-bottom: 20px;
    box-shadow: $shadow-2;
    padding: 10px;
    border-radius: 15px;
    width: 48%;
   
    
}

.section_title {
    width: 100%;
    background-color: #4758f580;
    padding: 5px;
    border-radius: 15px;
    margin-bottom: 10px;
    color: white;
    @extend .center-row
}

.inventory_room_container {
    @extend .center-column;
    justify-content: flex-start;
    box-shadow: $shadow-2;
    border-radius: 20px;
    width: 48%;
    margin-bottom: 10px;
    padding-bottom: 20px;
    margin-top: 10px
}
