.header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: space-between;
    width: 100%;
    height: 70px;
    padding-left: 2%;
    padding-right: 2%;
    background-color: rgba(255, 255, 255, 0.10);
    backdrop-filter: blur(30px);
}
/* 
#sidebar a {
    color: black;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    text-decoration: none;
}

#sidebar button {
    color: black;
    padding: 10px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 10px;
    margin: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    text-decoration: none;
}

#sidebar a.active {
    color: white;
    background-color: #3b82f6;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    text-decoration: none;
    font-weight: 600
}

#sidebar a:hover {
    text-decoration: none;
    color: black;
    background-color: #b0caf7;
   
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
} */