@import "../../styles/variables.scss";
@import "../../styles/App.scss";

.toggle_container {
    @extend .center-row;
    margin-top: 10px;
    border: 4px solid #3b82f6;
    background-color: rgba(255,255,255,0.6);
    border-radius: 15px;
    box-shadow: 0px 0px 7px 0px rgba(0,0,0,0.55);
    display: flex;
}

.toggle_tab {
    padding: 5px 10px;
    cursor: pointer;
}

.toggle_tab_active {
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #c0bfbf;
    border-radius: 10px;
    background-color: #3b82f6;
    color: white
}
