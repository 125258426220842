@import "../../styles/variables.scss";
@import '../../styles/App.scss';


.container_template {
    @extend .center-column;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 20px;
    justify-content: flex-start;
}

.tab_container_template {
    @extend .center-row;
    justify-content: space-between;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 15px;
    box-shadow: $shadow-3;
    }



.container_new {
    @extend .container_template;
    box-shadow: 0px 5px 10px 2px rgba(53, 73, 229, 0.3), 0px -5px 10px 2px rgba(52, 92, 235, 0.3);
    
    .tab_container {
        @extend .tab_container_template;
        border: 4px solid $primary-color;
        .tab_box_active {
            background-color: $primary-color;
        }
    }
}


.container_history {
    @extend .container_template;
    box-shadow: 0px 5px 10px 2px rgba(233, 163, 98, 0.3), 0px -5px 10px 2px rgba(231, 171, 98, 0.3);
   
    .tab_container {
        @extend .tab_container_template;
        border: 4px solid rgb(213, 136, 65);
        .tab_box_active {
            background-color: rgb(213, 136, 65);
        }
    }
}

.container_booked {
    @extend .container_template;
    box-shadow: 0px 5px 10px 2px rgba(46, 182, 46, 0.3), 0px -5px 10px 2px rgba(52, 235, 52, 0.3);
   
    .tab_container {
        @extend .tab_container_template;
        border: 4px solid rgb(46, 182, 46);
        .tab_box_active {
            background-color: rgb(46, 182, 46);
        }
    }
}

// .container_history {
//     @extend .center-column;
//     width: 100%;
//     height: 100%;
//     overflow-y: scroll;
//     border-radius: 20px;
//     box-shadow: 0px 5px 10px 2px rgba(233, 163, 98, 0.3), 0px -5px 10px 2px rgba(231, 171, 98, 0.3);
//     justify-content: flex-start;
// }



.container_booked {
    @extend .container_new;
    box-shadow: 0px 5px 10px 2px rgba(46, 182, 46, 0.3), 0px -5px 10px 2px rgba(52, 235, 52, 0.3);
}

.tab_box {
    padding: 5px 10px;
    cursor: pointer;
}
.tab_box_active {
    padding: 5px 10px;
    cursor: pointer;
    border: 1px solid #c0bfbf;
    border-radius: 10px;
    background-color: #3b82f6;
    color: white
}

// .tab_container_new {
//     @extend .center-row;
//     justify-content: space-between;
//     border: 4px solid $primary-color;
//     background-color: rgba(255, 255, 255, 0.5);
//     border-radius: 15px;
//     box-shadow: $shadow-3;
//     .tab_box_active {
//         background-color: $primary-color;
//     }
// }

// .tab_container_history {
//     @extend .center-row;
//     justify-content: space-between;
//     border: 4px solid rgb(213, 136, 65);
//     background-color: rgba(255, 255, 255, 0.5);
//     border-radius: 15px;
//     box-shadow: $shadow-3;
//     .tab_box_active {
//         background-color: rgb(213, 136, 65);
//     }
// }


.inventory_list_history_panel_container {
    width: 60%;
    border-radius: 15px;
    padding: 5px;
    box-shadow: $shadow-2;
    // border: 1px solid #94baf7;
    margin-top: 30px;
    margin-bottom: 20px;
    // background-color: black
    background-color: $background-color-50
}

.inventory_select {
    border-radius: 15px;
    box-shadow: $shadow-2;
    width: 100%;
    padding: 10px
}