/*
Color
*/
$primary-color: #3b82f6;
$primary-color-light: #dbeafe;
$primary-color-50: #eff6ff;
$primary-color-400: #60a5fa;
$primary-color-dark: #1e40af;

$background-color: #f3f4f6;
$background-color-50:rgb(240, 240, 241);
$background-color-100:rgb(220, 220, 222)1;
$background-color-200: #cfcfd1;
$background-color-600: #959696;
/*
Shadow
*/
$shadow-1: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$shadow-2: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
$shadow-3: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
$shadow-3-1: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
$shadow-4: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
$shadow-5-color: rgba(136, 191, 149, 0.48) -2px -2px 2px 0px, rgba(220, 232, 182, 0.8) 2px 2px 5px 0px;
$shadow-6:  rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
$shadow-8: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
