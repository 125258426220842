@import "../../styles/variables.scss";
@import '../../styles/App.scss';


.section_container {
    @extend .center-column;
    box-shadow: $shadow-2;
    background-color: rgba(255,255,255,0.95);
    padding: 10px;
    border-radius: 10px;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 30px;
    width: 90%;

}

.section_title {
    width: 100%;
    background-color:#86aff1;
    box-shadow: $shadow-2;
    @extend .center-row;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px
}

.section_title_readonly {
    @extend .section_title;
    background-color: #f5b67a;
}

.text_input {
    background-color: #f6f5f5;
    border: 0px;
    border-color: #ededed;
    font-size: 14px;
    border-radius: 20px;
    display: flex;
    align-items: center;
}



.entry_list_container {
    display: flex;
    flex-direction: row;
    width: 96%;
    // border-top: 1px dashed gray;
    margin-top: 10px;
    justify-content: space-between;
    align-items: center;
    padding-top: 5px;
}

.entry_list_container_protection {
    @extend .entry_list_container;
    border-radius: 10px;
    box-shadow: $shadow-2;
    background-color: #f9f9f9;
}


.icon_button {
    background-color: white;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    min-width: 30px;
    box-shadow: $shadow-2;
}