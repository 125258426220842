@import '../styles/variables.scss';
@import "../styles/App.scss";

.steps_container {
    @extend.center-row
}

.step_container {

    margin-left: 20px;
    margin-right: 20px;
    padding: 15px;
    border-radius: 20px;
    margin-bottom: 10px;
    box-shadow: $shadow-2;
    background: linear-gradient(60deg, #eef2fd, #f3fef6);
    height: 350px;
    width: 350px;
}

.step_title {
    @extend .center-column;
    margin-top: 20px;
    align-self: flex-start;
    justify-self: flex-start;
    border: 2px solid black;
    border-radius: 200px;
    width: 30px;
    height: 30px;
    margin-left: calc(50% - 15px);
}

.step_title_text{
    font-size: 20px;
    font-weight: 800;
}

.step_content_container {
    @extend .center-column;
    height: 270px;
    width: 100%;
    padding: 10px;
}

.step_content {
   
    font-size: 15px !important;
    font-weight: 600 !important;
    font-family: 'Courier New', Courier, monospace !important;
}