@import "../../styles/variables.scss";
@import "../../styles/App.scss";

.content_container {
  border-radius: 20px;
  width: 100%;
  height: 100%;
  flex-direction: column;
  backdrop-filter: blur(20px);
}

.item_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  box-shadow: $shadow-1;
  width: 45%;
  // height: 100px;
  background-color: #edecead4;
}

.item_container_editable {
  @extend .item_container;
  background-color: #ffffffc6;
}

.header_container {
  @extend .center-row;
  width: 100%;
  position: sticky;
  margin-top: 10px;
}

.show_all_checkbox {
  @extend .center-row;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  box-shadow: $shadow-2;
}

.create_new_container {
  @extend .center-column;
  right: 1.5%;
  top: 5%;
  margin-right: 10px;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
  box-shadow: $shadow-6;
  background-color: #f9fcfae0;
  position: absolute;
  z-index: 20;
}

.create_new_item {
  @extend .center-column;
  margin-left: 5px;
  margin-top: 8px;
  margin-right: 5px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: $shadow-2;
  background-color: #ffffffdd;
}

.table_sort {
  position: absolute;
  right: 5%;
  top: 0%;
  border-radius: 50px;
  background-color: rgba(222, 230, 250, 0.5);
}
